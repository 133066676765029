import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { FaEnvelope, FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa"

export default function info() {
  return (
    <div className="container my-5">
      <div className="display-3 intro">
        Hello, I'm <br />
        <span className="name">Terence.</span>
      </div>
      <div className="h1 code mt-4 mb-3">{"{"}</div>
      <div className="text-muted mx-5 my-3 h3 text-justify info">
        A Canadian software developer in Hong Kong with a background in startups
        and data science.
        <br className="mb-2" /> Combining a passion for problem solving and a
        diverse area of expertise to deliver practical solutions and ensure the
        best user experiences.
      </div>
      <div className="h1 code mt-3 mb-4">{"}"}</div>
      <div className="h1 mt-5">
        <OutboundLink
          className="mr-5 icon"
          href={
            "mailto:\u0074\u0079\u006c\u0065\u0075\u006e\u0067\u0040\u0075\u0061\u006c\u0062\u0065\u0072\u0074\u0061\u002e\u0063\u0061"
          }
          target="_blank"
        >
          <FaEnvelope />
        </OutboundLink>
        <OutboundLink
          className="mr-5 icon"
          href="https://github.com/tyleung"
          target="_blank"
        >
          <FaGithub />
        </OutboundLink>
        <OutboundLink
          className="mr-5 icon"
          href="https://www.linkedin.com/in/terenceyleung"
          target="_blank"
        >
          <FaLinkedin />
        </OutboundLink>
        <OutboundLink
          className="mr-5 icon"
          href="https://www.instagram.com/tleung_"
          target="_blank"
        >
          <FaInstagram />
        </OutboundLink>
      </div>
    </div>
  )
}
